export default function Parallax() {

    var Rellax = require('rellax');

    new Rellax('#hero__parallax', {
        speed: -6,
        center: false,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: false
    });



    const rellaxin = document.querySelectorAll(".parallax");
    rellaxin.forEach(el => {
        const rellax = new Rellax(el, {
            relativeToWrapper: true,
            wrapper: el.parentElement,
            speed: 2,
            center: true,
            round: true,
            vertical: true,
            horizontal: false,
        });
        window.addEventListener("scroll", () => { // fix to init
            rellax.refresh();
        });
    });

    const parallaxc = document.querySelectorAll(".parallax-c");
    parallaxc.forEach(el => {
        const rellax = new Rellax(el, {
            relativeToWrapper: true,
            wrapper: el.parentElement,
            speed: 3,
            center: true,
            round: true,
            vertical: true,
            horizontal: false,
        });
        window.addEventListener("scroll", () => { // fix to init
            rellax.refresh();
        });
    });

    const parallaxs = document.querySelectorAll(".parallax-s");
    parallaxs.forEach(el => {
        const rellax = new Rellax(el, {
            relativeToWrapper: true,
            wrapper: el.parentElement,
            speed: -1,
            center: true,
            round: true,
            vertical: true,
            horizontal: false,
        });
        window.addEventListener("scroll", () => { // fix to init
            rellax.refresh();
        });
    });


    const parallaxh = document.querySelectorAll(".parallax-h");
    parallaxh.forEach(el => {
        const rellax = new Rellax(el, {
            relativeToWrapper: true,
            wrapper: el.parentElement,
            speed: 2,
            center: true,
            round: true,
            vertical: false,
            horizontal: true,
        });
        window.addEventListener("scroll", () => { // fix to init
            rellax.refresh();
        });
    });

    const parallaxSecure = document.querySelectorAll(".secure-hero__parallax");
    parallaxSecure.forEach(el => {
        const rellax = new Rellax(el, {
            relativeToWrapper: true,
            wrapper: el.parentElement,
            speed: -4,
            center: true,
            round: true,
            vertical: true,
            horizontal: false,
        });
        window.addEventListener("scroll", () => { // fix to init
            rellax.refresh();
        });
    });

      
};

