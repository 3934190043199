export default function Carousel() {
 

    $('#hero').each(function (index, sliderWrap) {
        var $slider = $(sliderWrap).find('#hero__gallery');
        $slider.slick({
            dots: true,
            arrows: true,
            speed: 700,
            infinite: true,
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            draggable: true,
            lazyLoad: 'ondemand',
            pauseOnHover: false,
            fade: true,
            cssEase: 'linear'
        });
    });

    
};  