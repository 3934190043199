"use strict";
import $ from 'jquery';
import 'is-in-viewport';
window.jQuery = $;
import 'slick-carousel';
import Tab from 'bootstrap/js/dist/tab'

import CookieBanner from './components/cookie-banner';
import Parallax from './components/parallax';
import Carousel from './components/carousel';

jQuery(document).ready(() => {
    CookieBanner();
    Parallax();
    Carousel();
    
    $('.js-fade-in-up:in-viewport').addClass('o-fade-in-up');
});

$(window).scroll(function () {
    $('.js-fade-in-up:in-viewport').addClass('o-fade-in-up');
});
